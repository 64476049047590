import {
  ElectricityValues,
  getMeterFields,
  MeterTypes,
  RenderMeterFieldsSettings,
  WaterValues,
} from "kui-crm"
import { addToArrayByCondition } from "kui-utils"
import fullMeterInfoStepSettings from "./content"

type MeterFieldsSettings = {
  withMeterType: boolean
  setMeterType: (type: MeterTypes) => void
} & RenderMeterFieldsSettings

const getFullMeterFields = ({
  withMeterType,
  setMeterType,
  resource,
  ...other
}: MeterFieldsSettings) => [
  ...addToArrayByCondition(withMeterType, {
    label: "Тип",
    name: "type",
    options: resource === "water" ? WaterValues : ElectricityValues,
    handleChange: setMeterType,
    variant: "select",
  }),
  ...getMeterFields({ resource, ...other }, fullMeterInfoStepSettings),
]

export default getFullMeterFields
