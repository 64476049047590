import React from "react"
import styled from "@emotion/styled"
import { Box, Button } from "kui-basic"
import { MapIcon, PhoneIcon } from "kui-icon"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import ApartmentMap from "../ApartmentMap"

const ApartmentInfoFooter = () => {
  const { apartmentInfo } = useApartmentStore()
  const [isMapOpen, openMap, closeMap] = useToggle()

  return (
    <StyledWrapper>
      <Box mb={2}>
        <Button
          fullWidth
          size="s"
          variant="whiteWithGray"
          endIcon={<MapIcon />}
          onClick={openMap}
        >
          Посмотреть на карте
        </Button>
      </Box>
      <a href={`tel:+${apartmentInfo?.landlordPhone}`}>
        <Button fullWidth size="s" variant="orange" endIcon={<StyledIcon />}>
          Позвонить собственнику
        </Button>
      </a>

      <ApartmentMap isOpen={isMapOpen} handleClose={closeMap} />
    </StyledWrapper>
  )
}

export default observer(ApartmentInfoFooter)

const StyledWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 24px 0;
  width: 100%;
  background: ${({ theme }) => theme.palette.background.light2};
`

const StyledIcon = styled(PhoneIcon)`
  path {
    stroke: ${({ theme }) => theme.palette.brand.main};
    fill: transparent !important;
  }
`
