import { ApartmentParams } from "../../../../../../types/store/apartments"

type ApartmentInfoFieldParams = {
  label: string
  name: keyof ApartmentParams
}

const apartmentInfoFields: ApartmentInfoFieldParams[] = [
  { label: "Подъезд", name: "entrance" },
  { label: "Код домофона", name: "intercomCode" },
  { label: "Этаж", name: "floor" },
  { label: "Собственник", name: "landlordName" },
]

export default apartmentInfoFields
