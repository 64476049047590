import React, { ReactNode } from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { observer } from "mobx-react"
import Card from "../../../../../../components/common/Card"
import apartmentInfoFields from "../ApartmentAddressCard/content"
import useApartmentStore from "../../../../../../hooks/useApartmentStore"

const ApartmentInfoCard = () => {
  const { apartmentInfo } = useApartmentStore()

  return (
    <Card>
      {apartmentInfoFields.map((field) => (
        <StyledLine container justify="space-between">
          <Caption size="xs" color="fiftyP">
            {field.label}
          </Caption>
          <Caption size="s" weight={500}>
            {apartmentInfo?.[field.name] as ReactNode}
          </Caption>
        </StyledLine>
      ))}
    </Card>
  )
}

export default observer(ApartmentInfoCard)

const StyledLine = styled(Grid)`
  width: 100%;
  &:not(:first-of-type) {
    padding-top: 14px;
  }
  &:not(:last-of-type) {
    padding-bottom: 14px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`
