import React from "react"
import { RouteInterface } from "./types"
import ApartmentsPage from "../pages/ApartmentsPage"
import SignInPage from "../pages/SignInPage"
import ApartmentPage from "../pages/ApartmentPage"
import InspectionPage from "../pages/InspectionPage"

const routes: RouteInterface[] = [
  {
    path: "/signin",
    content: <SignInPage />,
    auth: false,
  },
  { path: "/:id", content: <ApartmentPage />, auth: true },
  {
    path: "/:id/inspections/:inspection_id",
    content: <InspectionPage />,
    auth: true,
  },
  { path: "/", content: <ApartmentsPage />, auth: true },
]

export default routes

export const DynamicRoutes = {
  apartment: (id: number) => `/${id}`,
  inspection: (apartmentId: number, inspectionId: number) =>
    `/${apartmentId}/inspections/${inspectionId}`,
}
