import React, { useEffect } from "react"
import { useParams } from "react-router"
import { observer } from "mobx-react"
import { Container } from "kui-basic"
import { LoaderState } from "kui-crm"
import useInspectionStore from "./store"
import InspectionForm from "./components/InspectionForm"
import Title from "../../components/common/Title"
import inspectionTypeLabels from "../ApartmentPage/common/InspectionFirstScreen/InspectionTypeButton/content"
import Page from "../../components/common/Page"
import InspectionFeedbackBlock from "./components/InspectionFeedbackBlock"

const InspectionPage = () => {
  const { id, inspection_id } = useParams()
  const inspectionStore = useInspectionStore()
  const { fetchInspection, resetInspection, type, date } = inspectionStore
  const { actionLoader, loader } = inspectionStore

  const initInspectionPage = async () => {
    if (inspection_id && id) fetchInspection(Number(id), Number(inspection_id))
  }

  useEffect(() => {
    initInspectionPage()
    return () => resetInspection()
  }, [])

  if (!type) return null

  return (
    <Page loader={loader}>
      <Container>
        <Title
          title={`${inspectionTypeLabels[type]} от ${date?.toFormat(
            "dd.MM.yyyy",
          )}`}
        />
        <InspectionFeedbackBlock />
        <InspectionForm />
      </Container>

      <LoaderState loader={actionLoader} />
    </Page>
  )
}

export default observer(InspectionPage)
