import { useEffect, useState } from "react"
import { CompanyLiteParams, InputParams, MeterTypes } from "kui-crm"
import { UseFormReturn } from "react-hook-form"
import { MeterFormFieldsProps } from "./types"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import getFullMeterFields from "./getFullMeterFields"

export type MeterInfoFieldsProps = {
  form: UseFormReturn<any>
} & MeterFormFieldsProps

export type MeterInfoFields = InputParams<any>[]

const useMeterInfoFields = (props: MeterInfoFieldsProps): MeterInfoFields => {
  const { resource, index, form } = props
  const { apartmentInfo, inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const apartmentId = apartmentInfo?.id
  const defaultMetersValues = formStore.fields?.meters?.[index]
  const defaultType = resource === "water" ? "cold" : "T1"
  const withMeterType = resource === "water" || resource === "electricity"
  const defaultMeterType = withMeterType ? defaultType : null

  const [disableOperatingAccount, setDisableOperatingAccount] = useState(false)
  const [meterType, setMeterType] = useState<MeterTypes>(
    defaultMetersValues?.type || defaultMeterType,
  )
  const tariffFilter = `${apartmentId ? `&for_apartment=${apartmentId}` : ""}${
    withMeterType && meterType ? `&type=${meterType}` : ""
  }`

  const handleCompanyChange = (company: CompanyLiteParams) => {
    if (
      !apartmentInfo?.communalServicesAccount &&
      company.id === apartmentInfo?.company?.id
    ) {
      form.setValue(
        "communalServicesAccount",
        apartmentInfo?.company?.operatingAccount || "",
      )
      setDisableOperatingAccount(true)
    } else {
      setDisableOperatingAccount(false)
    }
  }

  const meterFields = getFullMeterFields({
    withMeterType,
    setMeterType,
    resource,
    isNewResource: false,
    withLastValue: false,
    withPassport: true,
    meterType,
    tariffFilter,
    disableOperatingAccount,
    withNextCheck: false,
    handleCompanyChange,
    apartmentId,
  })

  useEffect(() => {
    form.setValue(`meters.${index}.resource`, resource)
  }, [])

  return meterFields
}

export default useMeterInfoFields
