import {
  getBasicApartmentInfoFields,
  getDetailApartmentInfoFields,
  getHouseInfoStep,
  getRoomsApartmentInfoFields,
  getSecurityApartmentInfoFields,
} from "kui-crm_actions"
import checkAddressStepSettings from "../../../../pages/ApartmentPage/variants/InitialInspectionForm/CheckAddressStep/content"
import commonInspectionFields from "../../../../pages/ApartmentPage/common/InspectionFirstScreen/content"
import {
  detailFieldsSettings,
  houseFieldsSettings,
  roomsFieldsSettings,
  securityFieldsSettings,
} from "../../../../pages/ApartmentPage/variants/InitialInspectionForm/ApartmentMainInfoStep/content"

export const getInitialFields = (
  roomsNumber: number,
  bedroomsNumber: number,
) => {
  const basicFields = getBasicApartmentInfoFields(
    undefined,
    checkAddressStepSettings,
  )
  const houseFields = getHouseInfoStep(houseFieldsSettings)
  const securityFields = getSecurityApartmentInfoFields(securityFieldsSettings)
  const roomsFields = getRoomsApartmentInfoFields(roomsFieldsSettings)
  const detailedFields = getDetailApartmentInfoFields(
    roomsNumber,
    bedroomsNumber,
    detailFieldsSettings,
  )

  return [
    ...commonInspectionFields,
    ...basicFields,
    ...houseFields,
    ...securityFields,
    ...roomsFields,
    ...detailedFields,
  ]
}
