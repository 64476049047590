import React from "react"
import { Box, Container } from "kui-basic"
import styled from "@emotion/styled"
import { FormWrapperProps } from "./types"
import FormFooter from "../FormFooter"
import Title from "../Title"

const FormWrapper = <T extends object>(props: FormWrapperProps<T>) => {
  const { form, title, onSubmit, children, ...other } = props

  return (
    <Container>
      <StyledForm onSubmit={form.handleSubmit(onSubmit)}>
        <Title title={title} />
        <StyledContentWrapper mb={3}>{children}</StyledContentWrapper>
        <FormFooter {...other} />
      </StyledForm>
    </Container>
  )
}

export default FormWrapper

const StyledForm = styled.form`
  position: relative;
  min-height: calc(100vh - 49px);
  display: flex;
  flex-direction: column;
`

const StyledContentWrapper = styled(Box)`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`
