import AuthStore from "./AuthStore"
import ApartmentsPageStore from "./ApartmentsPageStore"
import CabinetStore from "./CabinetStore"

class RootStore {
  authStore: AuthStore

  apartmentsPageStore: ApartmentsPageStore

  cabinetStore: CabinetStore

  constructor() {
    this.authStore = new AuthStore()
    this.cabinetStore = new CabinetStore()
    this.apartmentsPageStore = new ApartmentsPageStore()
  }
}

export default RootStore
