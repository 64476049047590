import React from "react"
import { observer } from "mobx-react"
import useStore from "../../hooks/useStore"
import UpcomingInspectionsPage from "./UpcomingInspectionsPage"
import ObjectsNeedInitialPage from "./ObjectsNeedInitialPage"

const ApartmentsPage = () => {
  const { cabinetStore } = useStore()
  const { role } = cabinetStore

  console.log(role)

  return role === "consultant" ? (
    <ObjectsNeedInitialPage />
  ) : (
    <UpcomingInspectionsPage />
  )
}

export default observer(ApartmentsPage)
