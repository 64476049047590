import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import Page from "../../components/common/Page"
import useApartmentStore from "../../hooks/useApartmentStore"
import { EntityParams } from "../../types/common"
import InspectionFirstScreen from "./common/InspectionFirstScreen"
import InspectionSuccessScreen from "./common/InspectionSuccessScreen"
import InspectionForm from "./common/InspectionForm"

const ApartmentPage = () => {
  const { fetchApartment, loader, inspectionsStore, resetApartmentPage } =
    useApartmentStore()
  const [isCreated, setIsCreated] = useState(false)
  const { id } = useParams<EntityParams>()
  const formStore = inspectionsStore.creationForm

  useEffect(() => {
    if (id) fetchApartment(Number(id))
    return () => resetApartmentPage()
  }, [])

  const handleCreate = () => setIsCreated(true)

  if (isCreated) return <InspectionSuccessScreen />

  return (
    <Page loader={loader}>
      {formStore.step === 1 ? (
        <InspectionFirstScreen />
      ) : (
        <InspectionForm handleCreate={handleCreate} />
      )}
      <LoaderState loader={inspectionsStore.actionLoader} />
    </Page>
  )
}

export default observer(ApartmentPage)
