import React, { useRef } from "react"
import { useForm } from "react-hook-form"
import { PlusIcon } from "kui-icon"
import { Button } from "kui-basic"
import { FilesStore, setNestedNameValue } from "kui-crm"
import { observer } from "mobx-react"
import { InspectionImagesFields, InspectionImagesStepProps } from "./types"
import FormWrapper from "../../../../components/common/FormWrapper"
import UploadImages from "../../../../components/common/UploadImages"
import useApartmentStore from "../../../../hooks/useApartmentStore"

const InspectionImagesStep = (props: InspectionImagesStepProps) => {
  const {
    label,
    name,
    entityName,
    type,
    handleSubmit,
    actionHandler,
    actionLabel,
    ...other
  } = props
  const { inspectionsStore } = useApartmentStore()
  const filesStoreRef = useRef(new FilesStore("image"))
  const filesStore = filesStoreRef.current
  const formStore = inspectionsStore.creationForm
  const formattedName = entityName ? `${entityName}.${name}` : name
  const defaultValues = formStore?.getNestedField(formattedName)
  const defaultValuesWithType = { type, ...defaultValues }
  const form = useForm<InspectionImagesFields<typeof name>>({
    defaultValues: {
      [name]: !type ? defaultValues : defaultValuesWithType,
    },
  })
  const submitDisabled = filesStore.isUploading

  const handleFormSubmit = (data: InspectionImagesFields<typeof name>) => {
    if (filesStore.uploadedAllFiles) {
      const formattedData = {
        ...data,
        ...setNestedNameValue(`${name}.files`, filesStore.files, data),
      }
      formStore?.addValueToField(name, formattedData[name], entityName)
      if (handleSubmit) handleSubmit({ ...formattedData, type })
      else formStore?.nextStep()
    } else filesStore.uploadFiles()
  }

  const handleFormAction = (data: InspectionImagesFields<typeof name>) => {
    formStore?.addValueToField(name, data[name], entityName)
    if (actionHandler) actionHandler()
  }

  if (!formStore) return null

  return (
    <FormWrapper
      form={form}
      title={label}
      label={
        filesStore.uploadedAllFiles ? "Следующий этап" : "Загрузить фотографии"
      }
      onSubmit={handleFormSubmit}
      onPrev={formStore?.prevStep}
      disabled={submitDisabled}
      actionButton={
        actionHandler && (
          <Button
            fullWidth
            size="s"
            variant="orange"
            onClick={form.handleSubmit(handleFormAction)}
            endIcon={<PlusIcon />}
          >
            {actionLabel}
          </Button>
        )
      }
    >
      <UploadImages
        filesStore={filesStore}
        form={form}
        name={name}
        {...other}
      />
    </FormWrapper>
  )
}

export default observer(InspectionImagesStep)
