import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { HalfArrowNextIcon } from "kui-icon"
import { OptionButtonProps } from "./types"

const OptionButton = (props: OptionButtonProps) => {
  const { label, value, onClick } = props

  const handleClick = () => {
    onClick(value)
  }

  return (
    <StyledWrapper
      container
      alignItems="center"
      wrap="nowrap"
      justify="space-between"
      onClick={handleClick}
    >
      <Caption size="xs" color="fiftyP">
        {label}
      </Caption>
      <StyledIcon />
    </StyledWrapper>
  )
}

export default OptionButton

const StyledWrapper = styled(Grid)`
  &:not(:first-of-type) {
    padding-top: 12px;
  }
  &:not(:last-of-type) {
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledIcon = styled(HalfArrowNextIcon)`
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`
