import React from "react"
import { useForm } from "react-hook-form"
import { LinkField } from "kui-crm"
import { observer } from "mobx-react"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import InspectionTypesBlock from "../InspectionTypesBlock"
import { InspectionCommonInfoFormFields } from "./types"

const InspectionCommonInfoForm = () => {
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm

  const form = useForm<InspectionCommonInfoFormFields>({
    defaultValues: {
      ...formStore.fields,
    },
  })

  const handleSubmit = (data: InspectionCommonInfoFormFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <>
      <LinkField label="Ссылка на Dropbox" name="dropboxLink" form={form} />
      <InspectionTypesBlock onClick={form.handleSubmit(handleSubmit)} />
    </>
  )
}

export default observer(InspectionCommonInfoForm)
