const fillingInfoStepSettings = {
  type: { label: "Тип" },
  tag: { label: "Название (тег)" },
  serialNumber: { label: "Серийный номер" },
  numberOfSubjects: { label: "Количество" },
  name: { label: "Описание" },
  instruction: { label: "Инструкция" },
}

export default fillingInfoStepSettings
