import React from "react"
import { ButtonsPanel } from "kui-complex"
import { useTabIndex } from "kui-utils"
import styled from "@emotion/styled"
import InspectionFirstStep from "../InspectionFirstStep"
import InspectionsList from "../InspectionsList"

const tabs = ["Создание инспекции", "Список инспекций"]

const paths = ["?tab=inspection_creation", "?tab=inspections_list"]

const tabPanels = [<InspectionFirstStep />, <InspectionsList />]

const InspectionTabsPanel = () => {
  const activeTab = useTabIndex(paths)

  return (
    <StyledButtonsPanel
      tabs={tabs}
      tabPanels={tabPanels}
      paths={paths}
      activeTab={Number(activeTab)}
    />
  )
}

export default InspectionTabsPanel

const StyledButtonsPanel = styled(ButtonsPanel)`
  background: transparent;
  padding-bottom: 16px;
  .KUI-ButtonTabs {
    padding: 0;
  }
  .KUI-ButtonTab:not(.KUI-ButtonTab_active) {
    background: transparent;
  }
`
