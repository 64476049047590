import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import CabinetAgent from "../agent/CabinetAgent"
import { CabinetUserModel } from "../types/api/cabinet"

class CabinetStore {
  user: CabinetUserModel | null

  loader: Loader

  constructor() {
    this.user = null
    this.loader = new Loader(true)
    makeAutoObservable(this)
  }

  getUser = async () => {
    this.loader.startLoading()
    const [err, res] = await to(CabinetAgent.getUser())
    runInAction(() => {
      if (!err && res) {
        this.user = res
      } else {
        this.loader.setError("Ошибка получения пользователя", err)
      }
      this.loader.endLoading()
    })
  }

  get fullName() {
    return this.user?.first_name || this.user?.last_name
      ? `${this.user?.first_name ?? ""} ${this.user?.last_name ?? ""}`
      : this.user?.username || ""
  }

  get role() {
    return this.user?.user_role
  }
}

export default CabinetStore
