import React from "react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { UploadedImageProps } from "./types"
import UploadImageStatus from "../UploadImageStatus"

const UploadedImage = (props: UploadedImageProps) => {
  const { index, image, onClick } = props

  const handleClick = () => onClick(index)

  return (
    <Grid item xs={4}>
      <StyledWrapper>
        <StyledImage src={image.url} onClick={handleClick} />
        {image.uploadStatus !== "default" && (
          <Backdrop>
            <UploadImageStatus file={image} />
          </Backdrop>
        )}
      </StyledWrapper>
    </Grid>
  )
}

export default observer(UploadedImage)

const StyledWrapper = styled.div`
  height: 115px;
  width: auto;
  position: relative;
`

const StyledImage = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 115px;
  object-fit: cover;
`

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all ease-out 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
`
