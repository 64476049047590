import React from "react"
import { InspectionTypeButtonProps } from "./types"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import inspectionTypeLabels from "./content"
import OptionButton from "../../../../../components/common/OptionButton"

const InspectionTypeButton = ({ type, onClick }: InspectionTypeButtonProps) => {
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm

  const handleClick = () => {
    formStore.updateFormFields({ type })
    onClick()
  }

  return (
    <OptionButton
      label={inspectionTypeLabels[type]}
      value={type}
      onClick={handleClick}
    />
  )
}

export default InspectionTypeButton
