import React from "react"
import styled from "@emotion/styled"
import Card from "../../../../../components/common/Card"
import Title from "../../../../../components/common/Title"
import inspectionTypes from "./content"
import InspectionTypeButton from "../InspectionTypeButton"
import { InspectionTypesBlockProps } from "./types"

const InspectionTypesBlock = (props: InspectionTypesBlockProps) => (
  <StyledWrapper>
    <Title title="Выберите действие" />
    <Card>
      {inspectionTypes.map((type) => (
        <InspectionTypeButton key={type} type={type} {...props} />
      ))}
    </Card>
  </StyledWrapper>
)

export default InspectionTypesBlock

const StyledWrapper = styled.div`
  flex-grow: 1;
`
