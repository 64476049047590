const checkAddressStepSettings = {
  country: { label: "Страна" },
  region: { label: "Область" },
  city: { label: "Город" },
  apartment: { label: "Адрес" },
  apartmentNumber: { label: "Квартира" },
  zipCode: { label: "Индекс" },
}

export default checkAddressStepSettings
