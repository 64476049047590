const fullMeterInfoStepSettings = {
  withAutoSending: {
    label: "Авто чтение",
    hintMessage:
      "Данные счетчика автоматически передаются в управляющую компанию",
  },
  noTariff: {
    label: "Без тарифа",
    hintMessage:
      "Показания напрямую не влияют на стоимость, сумма расход берется из квитанции",
  },
  initialValue: { label: "Перовначальные показания" },
  lastValue: { label: "Последние показания" },
  initialValueT1: { label: "Перовначальные показания T1" },
  lastValueT1: { label: "Последние показания T1" },
  initialValueT2: { label: "Перовначальные показания T2" },
  lastValueT2: { label: "Последние показания T2" },
  initialValueT3: { label: "Перовначальные показания T3" },
  lastValueT3: { label: "Последние показания T3" },
  number: { label: "Номер" },
  startDate: { label: "Дата активации" },
  passportFile: { label: "Паспорт" },
  nextCheck: { label: "Дата проверки" },
  tariff: { label: "Тарифная группа" },
  company: { label: "Компания" },
  operationalAccountNumber: { label: "Номер лицевого счета" },
}

export default fullMeterInfoStepSettings
