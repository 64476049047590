import { InspectionTypes } from "kui-crm"

const inspectionTypes = [
  "initial",
  "inventory",
  "transfer",
  "regular",
] as InspectionTypes[]

export default inspectionTypes
