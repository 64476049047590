import React, { useEffect, useState } from "react"
import { Grid } from "kui-basic"
import { useFieldArray } from "react-hook-form"
import styled from "@emotion/styled"
import { useToggle } from "kui-utils"
import { InputWithController } from "kui-complex"
import { GalleryWithThumbnails } from "kui-crm"
import { observer } from "mobx-react"
import { UploadFilesFields, UploadImagesProps } from "./types"
import UploadButton from "./UploadButton"
import UploadedImage from "./UploadedImage"

const UploadImages = (props: UploadImagesProps) => {
  const { form, name, withComment, filesStore } = props
  const [selectedImage, setSelectedImage] = useState(0)
  const [isOpen, openSlider, closeSlider] = useToggle()
  const { fields, append, remove } = useFieldArray<UploadFilesFields>({
    control: form.control,
    name: withComment ? `${name || ""}.files` : name!,
  })
  const formattedImages = fields.map((image) => ({
    smallImageUrl: image.url || "",
    mediumImageUrl: image.url || "",
    largeImageUrl: image.url || "",
  }))

  const handleClick = (index: number) => {
    setSelectedImage(index)
    openSlider()
  }

  useEffect(() => {
    filesStore.setFiles(fields)
  }, [])

  return (
    <>
      <StyledWrapper container spacing={1}>
        {filesStore.files.map((field, index) => (
          <UploadedImage
            key={`${field.name}_${field.index}`}
            onClick={handleClick}
            index={index}
            image={field}
          />
        ))}
        <UploadButton
          append={append}
          filesStore={filesStore}
          fieldsLength={fields.length}
        />
      </StyledWrapper>
      {withComment && (
        <StyledInput
          label="Комментарий"
          form={form}
          isTextArea
          name={`${name}.comment`}
        />
      )}
      <GalleryWithThumbnails
        isOpen={isOpen}
        handleClose={closeSlider}
        images={formattedImages}
        activeSlide={selectedImage}
        onDelete={remove}
      />
    </>
  )
}

UploadImages.defaultProps = {
  withComment: true,
}

export default observer(UploadImages)

const StyledWrapper = styled(Grid)`
  position: relative;
  padding-bottom: 8px;
  flex-grow: 1;
  align-content: flex-start;
`

const StyledInput = styled(InputWithController)`
  position: sticky;
  width: 100%;
  bottom: 88px;
  left: 0;
`
